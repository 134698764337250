import { mapHelper } from 'common/utils'

// 计划状态
const status = [
  {
    text: '未开始',
    value: 0
  },
  {
    text: '已开始',
    value: 1
  },
  {
    text: '已完成',
    value: 2
  },
  {
    text: '已结束',
    value: 3
  },
  {
    text: '已作废',
    value: 4
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 计划类型
const scopeType = [
  {
    text: '平台级',
    value: 1
  },
  {
    text: '公司级',
    value: 2
  },
  {
    text: '项目级',
    value: 3
  }
]

const {
  map: scopeTypeMap,
  setOps: scopeTypeOps
} = mapHelper.setMap(scopeType)

const checkScore = [
  {
    text: '合格',
    value: 1
  },
  {
    text: '不合格',
    value: 0
  }
]
const {
  map: checkScoreMap,
  setOps: checkScoreOps
} = mapHelper.setMap(checkScore)
export {
  statusMap,
  statusOps,
  scopeTypeMap,
  scopeTypeOps,
  checkScoreOps,
  checkScoreMap
}
