<!--
 * @Description: 品质管理-计划报告-任务报告
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityPlanReportList-wrapper">
    <list
      ref="list"
      :exportPermission="exportPermission"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :hasOperateColumn="false"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack" />
      </template>

      <template #searchSlot>
        <v-input label="任务名称" v-model="searchParams.planName" />
        <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
        <!-- <v-select label="检查人" v-model="searchParams.responsibleUserId" :options="responsibleUserOps"/> -->
        <v-input label="检查人" v-model="searchParams.executeUserName" />
        <v-datepicker
          type="rangedatetimer"
          label="计划开始时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planStartTimeS"
          :endTime.sync="searchParams.planStartTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="计划完成时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planEndTimeS"
          :endTime.sync="searchParams.planEndTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="检查开始时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.taskStartTimeS"
          :endTime.sync="searchParams.taskStartTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="检查完成时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.taskEndTimeS"
          :endTime.sync="searchParams.taskEndTimeE"
        />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckTaskReportListURL,
  exportQualityCheckTaskReportList
} from './api'
import {
  checkScoreOps,
  checkScoreMap,
  statusMap,
  statusOps
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'qualityPlanReportList',
  data () {
    return {
      searchUrl: getQualityCheckTaskReportListURL,
      exportUrl: exportQualityCheckTaskReportList,
      exportPermission: 'export2',
      exportMethod: 'get',
      communityParams,
      statusOps: statusOps(1),
      statusMap,
      checkScoreOps,
      checkScoreMap,
      responsibleUserOps: [],
      searchParams: {
        planId: '',
        planName: '',
        communityId: '',
        executeUserName: '',
        planStartTimeS: '',
        planStartTimeE: '',
        planEndTimeS: '',
        planEndTimeE: '',
        taskStartTimeS: '',
        taskStartTimeE: '',
        taskEndTimeS: '',
        taskEndTimeE: ''
      },
      headers: [
        {
          prop: 'taskName',
          label: '任务名称'
        },
        {
          prop: 'communityName',
          label: '检查对象'
        },
        {
          prop: 'planStartTime',
          label: '计划起止时间',
          formatter (row) {
            return `${row.planStartTime}~${row.planEndTime}`
          }
        },
        {
          prop: 'taskStartTime',
          label: '首次检查时间'
        },
        {
          prop: 'taskEndTime',
          label: '结束检查时间'
        },
        {
          prop: 'executeUserName',
          label: '检查人'
        },
        {
          prop: 'status',
          label: '任务状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'checkScore',
          label: '检查得分',
          formatter (row) {
            // displayModel 1 按评分 2 按是否合格
            if (row.displayModel === 1) {
              return row.checkScore
            }
            return checkScoreMap[row.checkScore]
          }
        }
      ]
    }
  },
  created () {
    // this.searchParams.planId = this.$route.query.planId
    this.searchParams.planName = this.$route.query.planName
    this.searchParams.communityId = this.$route.query.communityId
    this.$setBreadcrumb('任务报告')
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
